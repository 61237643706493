require('lib/head')
var bundle = {
  commonFormats: require('rdf-formats-common'),
  rdf: require('rdf-ext'),
  rdfFetch: require('rdf-fetch'),
  stringToStream: require('string-to-stream'),
  DatasetStore: require('rdf-store-dataset'),
  FilterStream: require('rdf-stream-filter'),
  JsonLdParser: require('rdf-parser-jsonld'),
  JsonLdSerializer: require('rdf-serializer-jsonld-ext'),
  N3Parser: require('rdf-parser-n3'),
  NTriplesSerializer: require('rdf-serializer-ntriples'),
  Readable: require('readable-stream'),
  Sink: require('rdf-sink'),
  Source: require('rdf-source'),
  SparqlStore: require('rdf-store-sparql'),
  TripleToQuadTransform: require('rdf-transform-triple-to-quad'),
  WebStore: require('rdf-store-web')}
module.exports = bundle
if (window) { Object.keys(bundle).forEach(function (key) { window[key] = bundle[key] })}
